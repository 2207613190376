import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-180f5cd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_form_render = _resolveComponent("form-render")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_form_render, {
            class: "process-form",
            ref: "formRender",
            forms: _ctx.forms,
            modelValue: _ctx.formData,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.formData) = $event))
          }, null, 8, ["forms", "modelValue"])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}